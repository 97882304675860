import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { FiLogIn } from 'react-icons/fi';
import { googleLogin, reset } from './features/auth/authSlice'
import metricsService from './features/data/metricsService'
import AuthSignup from './AuthSignup'

function GoogleOAuth({ userId }) {
    const [newAccount, setNewAccount] = useState(false);
    const [userMessage, setUserMessage] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, processing, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

    const determineStatus = async (code) => {
        dispatch(googleLogin(code))
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            determineStatus(code);
            window.history.replaceState({}, document.title, window.location.pathname);
        }
        if (user) {
            metricsService.convertSession(user, userId)
            navigate('/')
        }
        if (message === 'unverified') {
            setUserMessage("Your Google account email is unverified. Cannot login with Google.")
        } else
        if (message === '500') {
            setUserMessage("Cannot login with Google at this time. Please contact feedback@chatwatch.tv if issue persists.")
        } else if (message) {
            if (!email) {
            setNewAccount(true)
            setEmail(message)
            }
        }
        dispatch(reset)
    }, [user, message, dispatch]);
    return (
        <div className="home-container">
            <div className="home-logo">
                <img src="/chatwatch-word.png" alt="" />
            </div>
            {userMessage && (
                <>
                    <div className="auth-error-message">
                        <h3>{userMessage}</h3>
                    </div>
                    <br></br>
                    <Link to="/login"><div className='menu-logout'>
                        <button><FiLogIn size='1.2rem' />    Log in</button>
                    </div></Link>
                </>
            )}
            {newAccount && <AuthSignup email={email} />}

        </div>
    );
}

export default GoogleOAuth;

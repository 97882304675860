import React, {useState, useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { sendPasswordReset, reset } from './features/auth/authSlice'
import { TitleContext } from './app/TitleContext';

import './styles.css';

function ForgotPassword() {
  // const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { pageTitle, updatePageTitle } = useContext(TitleContext);

  const {user, processing, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )
  useEffect(() => {
    updatePageTitle('Chatwatch - Forgot Password')
}, [])
  useEffect(() => {
    if(isError){
        setError(message)
    }
    if(isSuccess){
        setSuccess(message)
        setTimeout(() => {
            navigate('/login')
        }, 3000 )
    }
    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const isValidEmail = (email) => {
    var pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]{2,}$/;
    return pattern.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("")
    setSuccess("")
    if(!isValid) {
      setError("Please enter a valid email address")
    } else {
      if(!processing){
        dispatch(sendPasswordReset(email))
      }
    }
  }

  useEffect(() => {
    if (isValidEmail(email)) {
        setIsValid(true)
    } else {
        setIsValid(false)
    }
  }, [email])

  return (
    <div>
        <div className="home-container">
          <div className="home-logo">
            <img src="/chatwatch-word.png" alt="" />
          </div>
          <div className="subheader">
            <h3>Enter the email address associated with your Chatwatch account, and we will send you a password reset link.</h3>
          </div>
          <div className="login-container">
            <form>
                 <div className="auth-error-message">
                 { (error && !success) && (<h3>{error}</h3>)}{ success && !error && (<h4>{success}</h4>)}
                </div>
                <label>
                <input type="text" id="email" name="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </label>
                <button type="submit" onClick={handleSubmit}>Send a reset link</button>
            </form>
          </div>
        </div>
    </div>
  );
}

export default ForgotPassword;

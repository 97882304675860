import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ duration, endTime, pollId }) => {
  const [timer, setTimer] = useState(Math.floor((endTime - Date.now()) / 1000));

  useEffect(() => {
    // Reset the timer whenever the duration prop changes
    const timeLeft = Math.floor((endTime - Date.now()) / 1000);
    setTimer(timeLeft);
  }, [endTime, pollId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime, pollId]);

  // Format the timer value (assuming it represents seconds)
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className='poll-alert-time'>
      <h3>{formatTime(timer)}</h3>
    </div> 
  );
};

export default CountdownTimer;

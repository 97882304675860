import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { TitleContext } from './../app/TitleContext';

function Terms() {
    const { pageTitle, updatePageTitle } = useContext(TitleContext);
    useEffect(() => {
        updatePageTitle('Chatwatch - Terms of Service')
    }, [])
  return (
    <div className="home-container">
      <div className="home-logo">
        <img src="/chatwatch-word.png" alt="" />
      </div>
      <div className="terms">
        <Link to="/channels"><button>Back</button></Link>
        <br></br>
        <h2>Terms of Service</h2>
        <h3>Updated June 15, 2023</h3>
        <br></br>
        <h3>
            These Terms of Service ("Terms") govern your use of the chat application ("Chatwatch" or "the App"), a service provided by Matt and Kelly Kline ("We" or "Us").
            By accessing or using the App, you agree to be bound by these Terms. If you do not agree with any part of these Terms,
            you may not use the App.
        </h3>
        <br></br>
        <h2>
            Account Creation and User Responsibilities
        </h2>
        <br></br>
        <h3>
            Users are required to create an account to use Chatwatch.
            During the registration process, your chosen username, email address, and password are collected.
            You are responsible for maintaining the confidentiality of your account credentials.
            You agree to comply with the <u><Link to="/conduct">Code of Conduct</Link></u> and guidelines outlined below in relation to your use of the App.
        </h3>
        <br></br>
        <h2>
            App Functionality and User Conduct
        </h2>
        <br></br>
        <h3>
            Chatwatch is a public live chat designed for users to interact while watching the same live TV show or event. It does not support personal messaging, group chats, or forums.
            User-generated content is limited to text messages in the chat. Any form of media sharing, including unauthorized images or files, is not permitted.
            Chatwatch employs automatic filtering to censor profanity in user-generated content. However, its complete effectiveness cannot be guaranteed.
            Human moderators monitor the chat and may take necessary actions, including temporary or permanent bans, to enforce compliance with the <u><Link to="/conduct">Code of Conduct</Link></u>.
            Users are prohibited from engaging in spamming, harassment, sharing of illegal content, or any activity that violates applicable laws or infringes upon the rights of others.
            Users may not share links or attempt to circumvent the message filter in chat.
        </h3>
        <br></br>
        <h2>
            Privacy and Data Handling
        </h2>
        <br></br>
        <h3>
            We take privacy and data security seriously, and require minimum information from users.
            Do not share your password or any other private information with anyone, including Chatwatch employees, moderators, or other users.
            Chatwatch utilizes encryption and secure storage for sensitive information.
            We do not share user-specific data with third parties without your consent.
            For more information on our data handling practices, please refer to our Privacy Policy.
        </h3>
        <br></br>
        <h2>
            Disclaimers and Limitation of Liability
        </h2>
        <br></br>
        <h3>
            The App is provided "as is" and without warranties of any kind, express or implied.
            We do not guarantee the accuracy, reliability, or availability of the App's functionality, content, or user-generated content.
            We are not liable for any damages, losses, or harm arising out of your use of the App or any actions taken by other users.
        </h3>
        <br></br>
        <h2>
            Termination
        </h2>
        <br></br>
        <h3>
            We reserve the right to terminate or suspend your access to the App at any time and for any reason, including violation of these Terms. You may end your agreement to these Terms at any time by deactivating your accounts and discontinuing your use of the App.
        </h3>
        <br></br>
        <h2>
            Modifications
        </h2>
        <br></br>
        <h3>
            We may modify or update these Terms from time to time, and users will be notified of updates. By continuing to use the App, you accept any revised Terms.
        </h3>
        <br></br>
        <h2>
            Governing Law
        </h2>
        <br></br>
        <h3>
            These Terms are governed by and construed in accordance with all laws in the applicable jurisdiction, without regard to its conflict of laws principles.
        </h3>
        <br></br>
        <h2><u><Link to="/privacy">Privacy Policy</Link></u></h2>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default Terms;

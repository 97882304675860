import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login, logout, updateEmail, reset } from './features/auth/authSlice'
import Navbar from './Navbar'
import axios from 'axios'


function UpdateEmail() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [email, setEmail] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState("");
    const [password, setPassword] = useState('');
    const { user, processing, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

    useEffect(() => {
        if (isError) {
            setError(message)
        }
        if (isSuccess) {
            if (!verified) {
                dispatch(reset());
                setVerified(true);
            } else {
                navigate('/settings', { state: { message: "Email updated successfully." } });
            }
        }
        dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!processing) {
            setVerified(false)
            const username = user.username;
            const loginData = { username, password };
            dispatch(login(loginData))
        }
    }
    useEffect(() => {
        if (verified) {
            if (!processing) {
                const newEmail = email
                const token = user.token;
                const data = {token, user, newEmail }
                dispatch(updateEmail(data))
            }
        }
    }, [dispatch, verified])

    return (
        <>
            <Navbar prevPage="/settings" />
            <div className='home-container'>
                <div className="subheader">
                    <h2>Change Email</h2>
                </div>
                <br />
                <div className="settings-container">
                    <form>
                        <label>
                            <input type="text" id="email" name="email" placeholder="Enter new email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </label>
                        <label>
                            <input type="password" id="password" name="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </label>
                        <div className="auth-error-message">
                            <h3>{error}</h3>
                        </div>
                        <br />
                        <button type="submit" onClick={handleSubmit}>Update Email</button>
                        <div className='cancel'>
                            <button><Link to='/settings'>Cancel</Link></button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default UpdateEmail;

import React, { useState, useEffect, useContext } from 'react';
import Navbar from './Navbar';
import dataService from './features/data/dataService';
import { useNavigate } from 'react-router-dom';
import { TitleContext } from './app/TitleContext';

const Feedback = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('')
  const [feedback, setFeedback] = useState({
    category: 'General',
    description: ''
  });
  const { pageTitle, updatePageTitle } = useContext(TitleContext);

  const user = JSON.parse(localStorage.getItem('user'))

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dataService.postFeedback(user.username, feedback);
    setFeedback({
      category: 'General',
      description: '',
    });
    setMessage("Thank you!")
    setTimeout(() => {
      setMessage('')
      navigate('/channels'); // Data fetching is complete after a 1-second delay
    }, 1200)
  };
  useEffect(() => {
    updatePageTitle('Chatwatch - Feedback')
}, [])

  return (
    <>
      <Navbar />
      <div className="subheader">
        <h2>Feedback & Bug Report</h2>
      </div>
      <div className="settings-container">
          <div className="settings-grid">
            <div>
              <label>
                <h3>Category:</h3>
              </label>
            </div>
            <div className='feedback-options'>
              <select name="category" value={feedback.category} onChange={handleChange} style={{ width: '10rem', height: '2rem' }}>
              <option value="General">General</option>
                <option value="Login">Login</option>
                <option value="Signup">Signup</option>
                <option value="Channels Page">Channels Page</option>
                <option value="Chat">Chat</option>
                <option value="Settings">Settings</option>
              </select>
            </div>
          </div>
          <div className="feedback-container">
            <textarea name="description" value={feedback.description} placeholder="Leave feedback here" onChange={handleChange} />
            <button type="button" onClick={handleSubmit}>Submit Feedback</button>
            <h2>{message}</h2>
          </div>
          {/* <div className="changelog-container">
            <h2>Changelog:</h2>
            <br></br>
            <h3>[Jun 17]</h3>
            <h3>- Added more channels. Added search and filter functionality.</h3>
            <h3>[Jun 14]</h3>
            <h3>- Added Polls v1</h3>
            <h3>[Jun 5]</h3>
            <h3>- Added program episode titles</h3>
            <h3>- Updated chat formatting</h3>
            <h3>[Jun 4]</h3>
            <h3>- Fixed issues with program list loading and issues with settings page</h3>
            <h3>[Jun 3]</h3>
            <h3>- Added feedback page</h3>
            <br></br>
            <h2>Known issues:</h2>
            <br></br>
            <h3>- Profanity filter is too strict</h3>

          </div> */}
      </div>
    </>
  );
};

export default Feedback;

import React, { createContext, useState } from 'react';

export const darkModeContext = createContext();

export function DarkModeProvider(props) {
    const [darkMode, setDarkMode] = useState(false);
    return (
      <darkModeContext.Provider value={[darkMode, setDarkMode]}>
        {props.children}
      </darkModeContext.Provider>
    );
}
import React, {useState, useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { sendPasswordReset, reset, resetPass } from './features/auth/authSlice'
import { TitleContext } from './app/TitleContext';
import './styles.css';

function ResetPassword() {
  // const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetToken = searchParams.get('token');
  const { pageTitle, updatePageTitle } = useContext(TitleContext);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const {user, processing, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )
  useEffect(() => {
    updatePageTitle('Chatwatch - Reset password')
}, [])
  useEffect(() => {
    if (resetToken) {

    }
  }, [resetToken])

  useEffect(() => {
    if(isError){
        setError(message)
    }
    if(isSuccess){
        setSuccess(message)
        setTimeout(() => {
            navigate('/login')
        }, 3000 )
    }
    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])


  const handleSubmit = (e) => {
    e.preventDefault();
    setError("")
    setSuccess("")
    if(password != password2) {
      setError("Passwords do not match")
    } else {
      if(!processing){
        dispatch(resetPass({
          token: resetToken,
          pass: password
        })
        )
      }
    }
  }

  return (
    <div>
        <div className="home-container">
          <div className="home-logo">
            <img src="/chatwatch-word.png" alt="" />
          </div>
          <div className="subheader">
            <h3>Enter the email address associated with your Chatwatch account, and we will send you a password reset link.</h3>
          </div>
          <div className="login-container">
            <form>
                 <div className="auth-error-message">
                 { (error && !success) && (<h3>{error}</h3>)}{ success && !error && (<h4>{success}</h4>)}
                </div>
                <label>
                <input type="password" id="password" name="password" placeholder="Enter new password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </label>
              <label>
                <input type="password" id="password2" name="password2" placeholder="Confirm new password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
              </label>
                <button type="submit" onClick={handleSubmit}>Update Password</button>
            </form>
          </div>
        </div>
    </div>
  );
}

export default ResetPassword;

import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import { FiLogIn } from 'react-icons/fi';
import { TitleContext } from './app/TitleContext';


function Logout() {
  const { pageTitle, updatePageTitle } = useContext(TitleContext);

  useEffect(() => {
    updatePageTitle('Chatwatch - Logged out')
}, [])

  return (
    <div className="home-container">
      <div className="home-logo">
        <img src="/chatwatch-word.png" alt="" />
      </div>
      <div className="subheader">
        <h3>You have successfully logged out.</h3>
      </div>
      <br></br>
      <Link to="/login"><div className='menu-logout'>
      <button><FiLogIn size='1.2rem' />    Log in</button>
        </div></Link>
    </div>
  );
}

export default Logout;

import { useState, useEffect } from 'react';
import emotes from '../features/chat/emotes'
import modIcon from '../png/mod-icon.png'
import adminIcon from '../png/admin-icon.png'

function Message({ message }) {
  // add mod or admin icon if user is mod - mods list passed as prop to Message
  const [userIcon, setUserIcon] = useState(null);
  const [userColor, setUserColor] = useState('');
  useEffect(() => {
    if (message.privilege === 'admin') {
      setUserIcon(adminIcon);
    } else if (message.privilege === 'moderator') {
      setUserIcon(modIcon);
    } else {
      setUserIcon(null);
    }
  }, [message]);
  const pattern = new RegExp(emotes.map(emote => emote.keyword).join('|'), 'g');
  if(!message.text) {
    message.text = message.message
  }
  const replacedText = message.text.replace(pattern, function (matched) {
    const emote = emotes.find(emote => emote.keyword === matched);
    return `<img src="${emote.icon}" alt="${emote.keyword}" style="vertical-align: middle; margin-left: 2px;">`;
  });
  return (
    (message.system) ?
      <div className="message">
        <p className="text">
          <span className="systemText" dangerouslySetInnerHTML={{ __html: `${replacedText}` }}></span>
        </p>
      </div>
      :
      <div className="message">
        <p className="text">
        {userIcon && <span className="user-icon"><img src={userIcon} alt="User Icon" /></span>}
          <span className="user" style={{ color: message.userColor }}>{message.user}</span>
          <span className="text" dangerouslySetInnerHTML={{ __html: `: ${replacedText}` }}></span>
        </p>
      </div>
  );
}

export default Message
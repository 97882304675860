import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TitleContext } from './app/TitleContext';
import { FiLogIn } from 'react-icons/fi';

function AccountCreated() {
  const [verified, setVerified] = useState(false);
  const { pageTitle, updatePageTitle } = useContext(TitleContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authParam = urlParams.get('auth');

    if (authParam) {
      setVerified(true);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    updatePageTitle('Chatwatch - Account Created')
}, [])

  return verified ? (
    <div className="home-container">
      <div className="home-logo">
        <img src="/chatwatch-word.png" alt="" />
      </div>
      <div className="subheader">
        <h3>Success! Click below to get started.</h3>
      </div>
      <Link to="/">
        <div className="menu-logout">
          <button>
            Go to channels
          </button>
        </div>
      </Link>
    </div>
  ) : (
    <div className="home-container">
      <div className="home-logo">
        <img src="/chatwatch-word.png" alt="" />
      </div>
      <div className="subheader">
        <h3>Success! Please check your email for a verification link.</h3>
      </div>
      <Link to="/login">
        <div className="menu-logout">
          <button>
            <FiLogIn size="1.2rem" /> Log in
          </button>
        </div>
      </Link>
    </div>
  );
}

export default AccountCreated;

import React, { createContext, useState } from 'react';

export const TitleContext = createContext();

export const TitleProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('Chatwatch');

  const updatePageTitle = (newTitle) => {
    setPageTitle(newTitle);
  };

  return (
    <TitleContext.Provider value={{ pageTitle, updatePageTitle }}>
      {children}
    </TitleContext.Provider>
  );
};
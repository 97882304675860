import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaLaptopHouse, FaTimes } from 'react-icons/fa';
import PollElement from '../components/PollElement'
import CountdownTimer from './CountdownTimer';

function PollOverlay({ socket, poll, pollActive, pollOpen, setPollOpen, pollEnded, vote, setVote, pollResult, closePoll, showAlert, setShowAlert, togglePoll }) {
    const [pollId, setpollId] = useState('');
    const [pollEnd, setPollEnd] = useState('');
    const [pollDuration, setPollDuration] = useState('');
    const [prompt, setPrompt] = useState('');
    const [showBigPrompt, setShowBigPrompt] = useState(false);
    const hideAlert = () => {
        setShowAlert(false)
    }
    useEffect(() => {
        if (poll) {
            setPrompt(poll.prompt)
            setPollEnd(poll.endTime)
            setPollDuration(poll.duration)
        }
    }, [poll]);
    return (
        <>
            <div className='poll-overlay'>
                { pollOpen && showBigPrompt && pollEnded && (<div className='poll-sheen'>
                </div> )}
                <PollElement
                    poll={poll}
                    pollOpen={pollOpen}
                    setPollOpen={setPollOpen}
                    prompt={prompt}
                    pollEnded={pollEnded}
                    vote={vote}
                    setVote={setVote}
                    setShowAlert={setShowAlert}
                    pollResult={pollResult}
                    showBigPrompt={showBigPrompt}
                    setShowBigPrompt={setShowBigPrompt}
                />
                {pollActive && (
                    <div className={`poll-alert ${pollOpen ? 'fade-out' : 'fade-in'}`}>
                        <div className='poll-alert-close' onClick={hideAlert}>
                            <FaTimes size='.5em' />
                        </div>
                        <div className='poll-alert-text'>
                            <h3>{poll.creator} started a new poll!</h3>
                        </div>
                        {pollEnd && <CountdownTimer duration={pollDuration} endTime={pollEnd} pollId={pollId} />}
                    </div>
                )}
            </div>
            <div className={`poll-tab ${!pollOpen ? 'tab-closed' : ''}`} onClick={togglePoll}>
                {pollOpen ? <FaChevronUp size=".8em" /> : <FaChevronDown size=".8em" />}
            </div>
        </>
    );
}

export default PollOverlay
import React, { useEffect, useRef } from 'react';
import emotes from '../features/chat/emotes'

function EmojiModal({ modalOpen, closeModal, handleEmojiClick }) {
    const emojis = ['😀', '😄', '😊', '🙂', '😍', '🥰', '😎', '🤩', '😂', '🤣', '😃', '😉'];
    const overlayRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            const chatButton = document.getElementById('submitMessage');
            const isEnterKey = event.key === 'Enter';
            const isClickOutside = !overlayRef.current.contains(event.target);
            if (modalOpen && isEnterKey) {
                closeModal();
                chatButton.click();
            }
            if (modalOpen && isClickOutside) {
                closeModal();
            }
        };

        if (modalOpen) {
            document.addEventListener('click', handleClickOutside);
            document.addEventListener('keydown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleClickOutside);
        };
    }, [modalOpen, closeModal]);
    return (
        <div className="modal">
            <div className="modal-content" ref={overlayRef}>
                <div className="emoji-grid">
                    {emotes.map((emote, index) => (
                        <div key={index} className="emoji-item" onClick={() => handleEmojiClick(emote)}>
                            <img src={emote.icon} alt={emote.keyword} />
                        </div>
                    ))}
                    {/* {emojis.map((emoji, index) => (
                        <div key={index} className="emoji-item" onClick={() => handleEmojiClick(emoji)}>{emoji}</div>
                    ))} */}
                </div>
            </div>
        </div>
    );
}

export default EmojiModal
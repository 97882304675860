import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from './features/auth/authSlice';
import authService from './features/auth/authService';
import { TitleContext } from './app/TitleContext';
import Navbar from './Navbar';
import { red } from '@mui/material/colors';
import loadingSpinner from './png/loading.gif'


function Settings() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [message, setMessage] = useState(location.state?.message);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showEmailUpdate, setShowEmailUpdate] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(true);
  const [timezone, setTimezone] = useState('');
  const [displayColor, setDisplayColor] = useState('');
  const [prevPrefs, setPrevPrefs] = useState({});
  const { pageTitle, updatePageTitle } = useContext(TitleContext);
  const { user } = useSelector((state) =>
    state.auth
  )

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/')
  }
  const checkForUser = async (user) => {
    const responseData = await authService.getUser(user);
    if (responseData) {
      if (responseData.email === "Error occurred") {
        onLogout();
      }
      setEmail(responseData.email);
      const displayColor = responseData.userPrefs.displayColor
      const timezone = responseData.userPrefs.timezone;
      setDisplayColor(displayColor);
      setTimezone(timezone);
      setPrevPrefs({
        displayColor: displayColor,
        timezone: timezone
      })
    }
  };
  const handleDeleteAccount = () => {
    // Handle deleting the account
    setConfirmationMessage("Account deletion still in development.")
  };
  const handleDeleteConfirm = () => {
    setShowDeleteButton(false);
    setShowConfirm(true);
  };
  const handleDeleteCancel = () => {
    setShowConfirm(false);
    setShowDeleteButton(true);
  };
  const handleEmailUpdate = () => {
    setShowEmailUpdate(true);
  }
  const postEmailUpdate = () => {
    setShowEmailUpdate(false);
    setConfirmationMessage("Email updated successfully.")
  }
  const handlePasswordUpdate = () => {

  }
  const postPasswordUpdate = () => {

  }

  const handleSettingsSave = () => {
    const userPrefs = {
      displayColor: displayColor,
      timezone: timezone
    }
    if (userPrefs.displayColor != prevPrefs.displayColor || userPrefs.timezone != prevPrefs.timezone) {
      authService.postUserPrefs({
        token: user.token,
        username: user.username,
        userPrefs: userPrefs
      })
      setPrevPrefs(userPrefs);
      setMessage("Account settings updated.")
    } else {
    setMessage("")
    }
  };

  const handleDisplayColorChange = (e) => {
    setDisplayColor(e.target.value);
  };
  const timeZones = [
    { abbreviation: 'EST', offset: -5 },
    { abbreviation: 'CST', offset: -6 },
    { abbreviation: 'MST', offset: -7 },
    { abbreviation: 'PST', offset: -8 },
    { abbreviation: 'AST', offset: -4 },
    { abbreviation: 'NST', offset: -3.5 },
    { abbreviation: 'AKST', offset: -9 },
    { abbreviation: 'HST', offset: -10 },
  ];
  const colorKey = {
    red: 'crimson',
    green: 'green',
    blue: 'royalblue',
    purple: 'purple',
    yellow: 'gold',
    orange: 'darkorange'
  };
  useEffect(() => {
    if (user) {
      checkForUser(user);
    }
  }, [user, showConfirm, showEmailUpdate]);
  useEffect(() => {
    updatePageTitle('Chatwatch - Settings')
}, [])


  return (
    <>
      <Navbar />
      <div className='profile-container'>
        <div className="profile-header">
          <h2>Account Settings</h2>
        </div>
        <div className="success-message">
          {message && (<h3>{message}</h3>)}
        </div>
        {!email && !timezone && !displayColor ? (
          <div className="loading">
            <img src={loadingSpinner} alt="Loading" />
          </div>
        ) : (
          <div className='profile-list'>
            <div className='profile-option'>
              <label htmlFor="username">Username:</label>
              <div className='settings-update'>
              <h2>{user.username}</h2>
              </div>
            </div>
            <div className='profile-option'>
              <label htmlFor="email">Email:</label>
              <div className='settings-update'>
                <h2>{(email) ? email : "Loading..."}</h2>
                <Link to='/update-email'><button>Change Email</button></Link>
              </div>
            </div>
            <div className='profile-option'>
              <label htmlFor="password">Password:</label>
              <div className='settings-update'>
                <h2>***********</h2>
                <Link to='/update-password'><button>Change Password</button></Link>
              </div>
            </div>
            <div className='profile-option'>
              <label htmlFor="timezone">Timezone:</label>
              <select id="timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                {timeZones.map((timeZone) => {
                  const { abbreviation, offset } = timeZone;
                  const offsetString = offset >= 0 ? `+${offset}` : `${offset}`;
                  return (
                    <option key={abbreviation} value={abbreviation}>
                      {`${abbreviation} (GMT${offsetString})`}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='profile-option'>
              <label htmlFor="display-color">Display Color:</label>
              <select id="display-color" value={displayColor} onChange={(e) => setDisplayColor(e.target.value)}>
                <option value="red">Red</option>
                <option value="green">Green</option>
                <option value="blue">Blue</option>
                <option value="purple">Purple</option>
                <option value="yellow">Yellow</option>
                <option value="orange">Orange</option>
                {/* Add more display color options here */}
              </select>
            </div>
            <div className='settings-delete'>
              <button onClick={handleSettingsSave}>Save Settings</button>
            </div>
            {/*
          <div className='settings-delete'>
            {showDeleteButton && (
              <button onClick={handleDeleteConfirm}>Delete Account</button>
            )}
            {showConfirm && (
              <div className='settings-confirm-delete'>
                <h3>Type password to permanently delete your account</h3>
                <input type="password" id="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button onClick={handleDeleteAccount}>Delete my account</button>
                <button onClick={handleDeleteCancel}>Cancel</button>
              </div>
            )}
          </div> */}
          </div>
        )}
      </div>
    </>
  );
};
export default Settings;

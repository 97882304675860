const emoteItems = [
  'Notbad',
  'sadWill',
  'wellThunk',
  'Yike',
  'ohNEIN',
  'doubleUP',
  'redROSE',
  'tooEASY',
  'goodAnswer',
  'endZONE'
]

const emotesList = (emoteItems) => {
  let list = [];
  for (const emoteItem of emoteItems) {
    const emote = {
      keyword: emoteItem,
      icon: require(`../../png/emotes/${emoteItem}.png`)
    }
    list.push(emote);
  }
  return list;
}

const emotes = emotesList(emoteItems)

export default emotes
import React, { useState, useEffect } from 'react';

const PollElement = ({ poll, pollOpen, setPollOpen, prompt, pollEnded, pollResult, setVote, vote, setShowAlert, showBigPrompt, setShowBigPrompt }) => {
    const [options, setOptions] = useState([]);
    const [animationStarted, setAnimationStarted] = useState(false);
    // const [resultBars, setResultBars] = useState(null);
    useEffect(() => {
        if (pollEnded) {
            setShowBigPrompt(true)
            setShowAlert(true)
            setPollOpen(true)
            const timer = setTimeout(() => {
                setAnimationStarted(true);
                setShowBigPrompt(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [pollEnded]);
    if (pollResult.length !== 0 && !resultBars) {
        var resultBars;
        // const totalVotes = pollResult.reduce((sum, count) => sum + count, 0);
        const maxCount = Math.max(...pollResult);
        const widthValues = pollResult.map(count => `${((count / maxCount) * 100) + 2}%`);
        resultBars = options.map((option, index) => (
            <div className="poll-results-single-bar" style={{ width: widthValues[index] }} >
                <div className="fill" style={{ animation: `${animationStarted ? 'growFromRight 0.5s ease-in-out ' + (index * 0.2) + 's forwards' : ''}` }}>
                <h3>{pollResult[index] !== 0 ? pollResult[index] : ''}</h3>
                </div>
            </div>
        ));
    }
    const submitVote = (option) => {
        if (vote === null) {
            setVote(option)
            setTimeout(() => {
                setPollOpen(false)
                // setTimeout(() => {
                //     setShowAlert(false)
                // }, 250)
            }, 2000)
        }
    };
    const pollOptionClass = (index) => {
        return vote === index ? 'poll-option-button clicked' : 'poll-option-button';
    };

    useEffect(() => {
        if (poll) {
            const options = [poll.option1, poll.option2, poll.option3, poll.option4].filter(Boolean);
            setOptions(options);
        }
    }, [poll])
    return (
        <div className={`poll-element ${pollOpen ? 'fade-in' : 'fade-out'}`}>
            {!pollEnded && <div className="poll-options">
                <div className={pollOptionClass(0)} onClick={() => submitVote(0)}>
                    <h3>{options[0]}</h3>
                </div>
                <div className={pollOptionClass(1)} onClick={() => submitVote(1)}>
                    <h3>{options[1]}</h3>
                </div>
                {options.length > 2 && <div className={pollOptionClass(2)} onClick={() => submitVote(2)}>
                    <h3>{options[2]}</h3>
                </div> }
                {options.length > 3 && <div className={pollOptionClass(3)} onClick={() => submitVote(3)}>
                    <h3>{options[3]}</h3>
                </div> }
            </div>}
            {!pollEnded && <div className='poll-question'>
                <h3>{prompt}</h3>
            </div>}

            {pollEnded && <div className={`poll-prompt-big ${showBigPrompt ? 'fade-in' : 'fade-out'}`}>
                <h3>POLL RESULT</h3>
                <h2>{prompt}</h2>
            </div>}
            {pollEnded && <div className={`poll-results ${showBigPrompt ? 'fade-out' : 'fade-in'}`}>
                <div className='poll-results-options'>
                    <div className='container'>
                        { options.length > 0 && <h3>{options[0]}</h3> }
                        { options.length > 1 && <h3>{options[1]}</h3> }
                        { options.length > 2 && <h3>{options[2]}</h3> }
                        { options.length > 3 && <h3>{options[3]}</h3> }
                    </div>
                </div>
                <div className='poll-results-bars'>
                    {resultBars}
                </div>
            </div>}
        </div>
    );
};

export default PollElement;

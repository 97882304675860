import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    processing: false,
    isError: false,
    isSuccess: false,
    message: ''
}

export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
    try {
        return await authService.register(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const googleLogin = createAsyncThunk('auth/googleLogin', async (code, thunkAPI) => {
    try {
        const res = await authService.handleGoogleLogin(code)
        if (res.status === 200) {
            return res.data
        }
        if (res.status === 201) {
            return thunkAPI.rejectWithValue(res.data.email)
        }
        if (res.status === 403) {
            return thunkAPI.rejectWithValue('unverified')
        }
        if (res.status === 500) {
            return thunkAPI.rejectWithValue('500')
        }
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const resendVerification = createAsyncThunk('auth/resendVerification', async (username, thunkAPI) => {
    try {
        const response = await authService.resendVerification(username)
        const message = (response.message || response || response.toString())
        return thunkAPI.fulfillWithValue(message);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (emailToken, thunkAPI) => {
    try {
        const response = await authService.verifyEmail(emailToken)
        const message = (response.message || response || response.toString())
        return thunkAPI.fulfillWithValue(message);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const sendPasswordReset = createAsyncThunk('auth/sendPasswordReset', async (email, thunkAPI) => {
    try {
        const response = await authService.sendPasswordReset(email)
        const message = (response.message || response || response.toString())
        return thunkAPI.fulfillWithValue(message);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const resetPass = createAsyncThunk('auth/resetPass', async (email, thunkAPI) => {
    try {
        const response = await authService.resetPass(email)
        const message = (response.message || response || response.toString())
        return thunkAPI.fulfillWithValue(message);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const updatePass = createAsyncThunk('auth/updatePass', async (userData, thunkAPI) => {
    try {
        return await authService.updatePass(userData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const updateEmail = createAsyncThunk('auth/updateEmail', async (userData, thunkAPI) => {
    try {
        return await authService.updateEmail(userData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
export const login = createAsyncThunk('auth/login', async (userData, thunkAPI) => {
    try {
        return await authService.login(userData)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.error) || error.error || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const logout = createAsyncThunk('auth/logout', async() => {
    await authService.logout()
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.processing = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.processing = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(login.pending, (state) => {
                state.processing = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(googleLogin.pending, (state) => {
                state.processing = true
            })
            .addCase(googleLogin.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(googleLogin.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
            .addCase(updatePass.pending, (state) => {
                state.processing = true
            })
            .addCase(updatePass.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
            })
            .addCase(updatePass.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateEmail.pending, (state) => {
                state.processing = true
            })
            .addCase(updateEmail.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
            })
            .addCase(updateEmail.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(verifyEmail.pending, (state) => {
                state.processing = true
            })
            .addCase(verifyEmail.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
                state.message = action.payload
            })
            .addCase(verifyEmail.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(resendVerification.pending, (state) => {
                state.processing = true
            })
            .addCase(resendVerification.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
                state.message = action.payload
            })
            .addCase(resendVerification.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(sendPasswordReset.pending, (state) => {
                state.processing = true
            })
            .addCase(sendPasswordReset.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
                state.message = action.payload
            })
            .addCase(sendPasswordReset.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(resetPass.pending, (state) => {
                state.processing = true
            })
            .addCase(resetPass.fulfilled, (state, action) => {
                state.processing = false
                state.isSuccess = true
                state.message = action.payload
            })
            .addCase(resetPass.rejected, (state, action) => {
                state.processing = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { reset } = authSlice.actions
export default authSlice.reducer
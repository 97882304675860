import axios from 'axios'
let API_URL = ''
if (process.env.NODE_ENV === 'production') {
    API_URL = process.env.REACT_APP_BASE_URL;
}

const register = async (userData) => {
    const response = await axios.post(API_URL + '/signup', userData)
    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}

const resendVerification = async (username) => {
    const response = await axios.post(API_URL + '/resend-verification', { username })
    return response.data
}

const verifyEmail = async (emailToken) => {
    const response = await axios.post(API_URL + '/verify-email', { emailToken } )
    return response.data
}
const convertSession = async (user, userId) => {
    const response = await axios.post(API_URL + 'convert-session', { user, userId } )
    return response.data
}

const login = async (userData) => {
    const response = await axios.post(API_URL + '/login', userData)
    if (response.data) {
        const user = localStorage.getItem("user");
        if (user === null) {
            localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
    }
}

const logout = async () => {
    const user = localStorage.getItem("user");
    axios.post(API_URL + '/logout', user)
    localStorage.removeItem('user')
    return;
}
const sendPasswordReset = async (data) => {
    const response = await axios.post(API_URL + '/send-password-reset', { email: data } )
    return response.data
}
const resetPass = async (data) => {
    const response = await axios.post(API_URL + '/reset-password', data )
    return response.data
}
const updatePass = async (data) => {
    const response = await axios.post(API_URL + '/update-password', data )
    return response.data
}
const updateEmail = async (data) => {
    const response = await axios.post(API_URL + '/update-email', data )
    return response.data
}
const getUser = async (user) => {
    const response = await axios.post(API_URL + '/getuser', { token: user.token })
    return response.data
}
const postUserPrefs = async ({ token, username, userPrefs }) => {
    const response = await axios.post(API_URL + '/update-prefs', { token, username, userPrefs })
    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}
const handleGoogleLogin = async (code) => {
    const response = await axios.post(API_URL + '/api/oauth/google', { code: code })
    if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response
}

const authService = { register, resendVerification, login, logout, convertSession, sendPasswordReset, resetPass, updatePass, updateEmail, getUser, postUserPrefs, verifyEmail, handleGoogleLogin }

export default authService
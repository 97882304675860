import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Message from './components/Message';
import Navbar from './Navbar';
import EmojiModal from './components/EmojiModal';
import { TitleContext } from './app/TitleContext';
import PollOverlay from './components/PollOverlay';
import ShareButton from './components/ShareButton';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import channels from './Channels';
import { HiUsers } from 'react-icons/hi2';
import { FaRegSmile, FaAngleDoubleDown, FaPaperPlane, FaShare } from 'react-icons/fa';
import { RiBarChartHorizontalFill } from 'react-icons/ri'

function Chat(props) {
  const navigate = useNavigate()
  const params = useParams();
  const channelLink = params.channelLink;
  const user = JSON.parse(localStorage.getItem('user'))
  // const { user } = useSelector((state) =>
  // state.auth
  // )

  const { pageTitle, updatePageTitle } = useContext(TitleContext);
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [channelName, setChannelName] = useState('');
  const [showName, setShowName] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [list, setList] = useState('channels');
  const [users, setUsers] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [messageAndLoggedOut, setMessageAndLoggedOut] = useState(false)
  /* emoji tray */
  const [modalOpen, setModalOpen] = useState(false);
  const [poll, setPoll] = useState({});
  const [pollActive, setPollActive] = useState(false);
  const [pollOpen, setPollOpen] = useState(false);
  const [pollEnded, setPollEnded] = useState(false);
  const [vote, setVote] = useState(null);
  const [pollResult, setPollResult] = useState([]);
  const [showAlert, setShowAlert] = useState(true);
  const [shareOpen, setShareOpen] = useState(false);
  /* ref for message scroll */
  const chatMessagesRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const scrolledUp = useRef(false)
  /* ref for closing emoji tray on background click */

  const chatMessages = document.querySelector('.chat-messages');
  const getChannelInfo = (link) => {
    const channel = channels.find((channel) => channel.link === link);
    return channel ? {
      link: channel.link,
      name: channel.name,
      callSign: channel.callSign
    } : null;
  };
  const handleEmojiClick = (emote) => {
    setMessageInput((prevMessageInput) => prevMessageInput + emote.keyword);
  };
  const handleMessageSubmit = (e) => {
    if (user) {
      e.preventDefault();
      // Get message text
      let msg = messageInput;
      msg = msg.trim();
      if (!msg) {
        return false;
      }
      // Emit message to server
      socket.emit('chatMessage', user.username, msg, showName);
      // Clear input
      setMessageInput('');
      if (e.target.elements) {
        e.target.elements.msg.focus();
      } else if (e.target.form) {
        e.target.form.elements.msg.focus();
      }
    } else {
      setMessageAndLoggedOut(true)
    }
  };
  const joinRoom = (socket, user, channelLink) => {
    let thisChannel = getChannelInfo(channelLink)
    let thisProgram;
    if (thisChannel) {
      thisProgram = props.livePrograms.find(program => program.callSign === thisChannel.callSign && program.liveNow == true);
    }
    if (!thisProgram) {
      thisProgram = props.sportsPrograms.find(program => program.showName.replace(/^NFL: /, '').replace(/ /g, '-').toLowerCase() === channelLink)
    }
    if (!user) {
      user = {
        username: props.userId,
        userColor: 'white',
        userPrivilege: 'user'
      }
    }
    socket.emit('joinChannel', { room: channelLink, program: thisProgram, username: user.username, userColor: user.userColor, privilege: user.privilege });
  }
  const handleShare = async (event) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Chatwatch.tv',
          text: `Join me in the live chat for ${showName}!`,
          url: `https://www.chatwatch.tv/chat/${channelLink}`
        });
        console.log('Shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      event.stopPropagation();
      if (!shareOpen) {
        setShareOpen(true);
      }
    }
  };

  const openModal = () => {
    if (modalOpen) {
      closeModal();
    } else {
      setTimeout(() => {
        setModalOpen(true);
      }, 0)
    };
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const togglePoll = () => {
    setPollOpen((prevPollOpen) => !prevPollOpen);
  };
  const closePoll = () => {
    setPollOpen(false);
  };
  const handleScrollToBottom = () => {
    chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
  };
  const autoScrollDown = (newMessage) => {
    if (!scrolledUp.current || newMessage.user === user.username) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }
  useEffect(() => {
    const chatMessagesRefCurrent = chatMessagesRef.current;

    if (chatMessagesRefCurrent) {
      // Show/hide scroll button based on scroll position
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = chatMessagesRefCurrent;
        const isScrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
        setShowScrollButton(!isScrolledToBottom);
        scrolledUp.current = !isScrolledToBottom;
      };

      // Attach scroll event listener
      chatMessagesRefCurrent.addEventListener('scroll', handleScroll);

      // Cleanup
      return () => {
        chatMessagesRefCurrent.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    let thisProgram;
    let thisChannel = getChannelInfo(channelLink)
    if (thisChannel) {
    setChannelName(thisChannel.name);
    thisProgram = props.livePrograms.find(program => program.callSign === thisChannel.callSign && program.liveNow == true);
    } else {
      // not a channel from list, therefore sports
    setChannelName('Live Sports')
    setList('sports');
    thisProgram = props.sportsPrograms.find(program => program.showName.replace(/^NFL: /, '').replace(/ /g, '-').toLowerCase() === channelLink)
    }
    //Set program title while data loads
    if (!thisProgram) {
      setShowName('')
      updatePageTitle(`Chatwatch - ${channelName}`)
    } else {
      setShowName(thisProgram.showName);
      setEpisodeName(thisProgram.episodeName);
      updatePageTitle(`Chatwatch - ${thisProgram.showName} - ${channelName}`)
    }
  }, [props.livePrograms, channelLink]);
  useEffect(() => {
    let socket;
    socket = io(process.env.REACT_APP_SOCKET_URL)
    setSocket(socket);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        joinRoom(socket, user, channelLink)
      })
      socket.on('channelInit', ({ users, messages }) => {
        // setChannelName(channel);
        // setShowName(show);
        setUsers(users);
        setMessages(messages);
        setTimeout(() => {
          handleScrollToBottom();
        }, 1)
      })
      socket.on('disconnect', () => {
      })
      socket.on('channelUsers', (users) => {
        setUsers(users);
      });
      socket.on('pollInit', ({ poll }) => {
        setPoll(poll);
        setPollActive(true);
        setShowAlert(true);
        if (poll.users.length > 0) {
          let existingVote = null;
          // check array of user/vote objects to see if user already voted
          poll.users.forEach((element) => {
            if (element.user === user.username) {
              existingVote = element.vote;
              setVote(existingVote)
            }
          })
        }
      })
      socket.on('showAlert', () => {
        setShowAlert(true)
      })
      socket.on('pollEnd', ({ result }) => {
        setPollResult(result)
        setPollEnded(true)
        setTimeout(() => {
          setPollOpen(false)
          setTimeout(() => {
            setShowAlert(false)
            setPoll({})
            setPollResult([])
            setPollEnded(false)
            setVote(null)
            setPollActive(false)
          }, 500) // 1 second to animate poll closed before setting inactive
        }, 12000); // 10 seconds to show results, then close poll
      })
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on('message', (newMessage) => {
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setTimeout(() => {
          autoScrollDown(newMessage)
        }, 0);
      });
      socket.on('systemMessage', (sysMessage) => {
        let newMessage = { text: sysMessage, system: true };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setTimeout(() => {
          chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }, 0);
      });
    }
  }, [socket])

  useEffect(() => {
    if (!isNaN(vote) && pollActive) {
      socket.emit("pollVote", { user: user.username, channel: channelLink, vote: vote })
    }
  }, [vote])

  return (
    <>
      <div className="channel-header-container">
        <Navbar prevPage={list} messageAndLoggedOut={messageAndLoggedOut} setMessageAndLoggedOut={setMessageAndLoggedOut} />
        <div className="channel-header">
          <div className="channel-info">
            <div className="channel-name">
              <h3>{channelName}</h3>
            </div>
            <div className="channel-users">
              <div className="users-icon">
                <HiUsers size="1.1rem" color="white" />
              </div>
              <div className="user-count">
                <h3>{users.length}</h3>
              </div>

            </div>
          </div>
          <div className='program-box'>
            <div className="program-name">
              <div className={`program-text ${showName ? 'fade-in' : ''}`}>
                <h2>{showName}</h2>
                {episodeName && <h3>{episodeName}</h3>}
              </div>
            </div>
            <div className="share-button">
              <FaShare onClick={handleShare} />
            </div>
          </div>
        </div>
      </div>
      {shareOpen && <ShareButton shareOpen={shareOpen} setShareOpen={setShareOpen} showName={showName} shareUrl={channelLink} />}
      <div className="chat-container">
        <div className={`poll-container ${pollOpen ? 'poll-open' : showAlert && pollActive ? 'poll-active' : ''}`}>
          <PollOverlay
            socket={socket}
            poll={poll}
            pollActive={pollActive}
            pollOpen={pollOpen}
            setPollOpen={setPollOpen}
            pollEnded={pollEnded}
            vote={vote}
            setVote={setVote}
            pollResult={pollResult}
            closePoll={closePoll}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            togglePoll={togglePoll}
          />
        </div>
        <div className={`chat-window ${messages.length > 0 && showName ? 'fade-in' : ''}`}>
          <div className="chat-messages" ref={chatMessagesRef}>
            {messages.map(message => (
              <Message key={message.key} message={message} />
            ))}
          </div>
        </div>
        <div className="chat-form-container">
          {modalOpen && <EmojiModal modalOpen={modalOpen} closeModal={closeModal} handleEmojiClick={handleEmojiClick} />}
          {showScrollButton && !modalOpen && (
            <div className='scroll-modal'>
              <div className='scroll-modal-content'>
                <button className="scroll-button" onClick={handleScrollToBottom}>
                  New messages<FaAngleDoubleDown />
                </button>
              </div>
            </div>
          )}
          <form id="chat-form" className="chat-form" onSubmit={handleMessageSubmit}>
            <div className="textarea-container">
              <textarea
                rows="1"
                id="msg"
                type="text"
                placeholder="Send a message"
                required
                autoComplete="off"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    handleMessageSubmit(e);
                  }
                }}
                enterKeyHint='send'
                maxLength='240'
              ></textarea>
              <div className="emoji-icon-container">
                <FaRegSmile className="emoji-icon" onClick={openModal} />
              </div>
            </div>
          </form>
          <button id="submitMessage" className="btn" onClick={handleMessageSubmit}>Chat</button>
        </div>
      </div>
    </>
  );
}

export default Chat
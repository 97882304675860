import axios from 'axios'
let API_URL = ''
if (process.env.NODE_ENV === 'production') {
    API_URL = process.env.REACT_APP_BASE_URL;
}

const checkIn = async (userId, browserInfo) => {
  const userStr = localStorage.getItem("user");
  let username;
  if (userStr) {
    const user = JSON.parse(userStr);
    username = user.username;
  } else {
    username = null;
  }
  if (!browserInfo) {
    browserInfo = null;
  }
  axios.post(API_URL + '/checkin', { username, userId, browserInfo })
    .catch(error => {
      console.error('Error checking in:', error);
    });
  return;
}

const convertSession = async (user, userId) => {
  axios.post(API_URL + '/convert-session', { user, userId })
    .catch(error => {
      console.error('Error converting id session to user session:', error);
    });
}


const metricsService = { checkIn, convertSession }

export default metricsService
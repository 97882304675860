import React, { useState, useEffect, useContext } from 'react';
import { darkModeContext } from './darkModeContext';
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { FaCogs, FaTimes, FaSun, FaTv, FaUser, FaCircle, FaCouch, FaExclamationCircle, FaFootballBall } from 'react-icons/fa'
import { FiLogIn, FiLogOut } from 'react-icons/fi'
import Switch from '@mui/material/Switch';

function Menu({ menuOpen, toggleMenu, onLogout }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) =>
    state.auth
  )
  const [darkMode, setDarkMode] = useContext(darkModeContext);
  const [value, setValue] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const handleDarkMode = (event) => {
    setValue(event.target.checked)
    setDarkMode(value)
  }
  const toSignup = () => {
    navigate('/signup')
  }
  const toLogin = () => {
    navigate('/login')
  }
  const label = { inputProps: { 'aria-label': 'Dark Mode' } };

  useEffect(() => {
    if(menuOpen) {
      setOverlayVisible(true)
    }
    if(!menuOpen) {
      setOverlayVisible(false)
    }
  }, [menuOpen])
  const renderMenu = () => {
    if (!user) {
      return (
        <>
        <div className='menu-header'>
        <h2>Log in to Chatwatch</h2>
        </div>
        <div className="menu-login">
        <button onClick={toLogin}><FiLogIn size='1.2rem' />Login</button>
         <button onClick={toSignup}>Sign up</button>
        </div>
        </>
      );
    } else {
      return (
        <>
          <div className='menu-top'>
            <div className="menu-username">
              <FaCircle color="green" size="20" />
              <h1>{user.username}</h1>
            </div>
            <div className="menu-list">
              <ul>
                <li>
                  <FaCogs color="white" size="1.2rem" />
                  <Link to="/settings"><h3>Account Settings</h3></Link>
                </li>
                <li>
                  <FaFootballBall color="white" size="1.2rem" />
                  <Link to="/sports"><h3>Live Sports</h3></Link>
                </li>
                <li>
                  <FaTv color="white" size="1.2rem" />
                  <Link to="/channels"><h3>Live TV</h3></Link>
                </li>
                {/* <li>
                  <FaCouch color="white" size="1.2rem" />
                  <h3>Friends list</h3>
                </li>
                <li>
                  <div className="menu-darkMode">
                    <FaSun color="white" size="1.2rem" />
                    <h3>Dark mode</h3>
                    <Switch {...label} color="secondary" checked={value} onChange={handleDarkMode} name="darkModeSwitch" />
                  </div>
                </li> */}
                <li>
                  <FaExclamationCircle color="white" size="1.2rem" />
                  <Link to="/feedback"><h3>Feedback & Bug Report</h3></Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='menu-bottom'>
            <div className="menu-logout">
              <button onClick={onLogout}><FiLogOut size='1.2rem' />   Logout</button>
            </div>
            <div className='menu-bottom-links'>
              <Link to="/tos"><h3>Terms of Service</h3></Link>
              <Link to="/privacy"><h3>Privacy</h3></Link>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className={`menu ${menuOpen ? 'open' : ''}`}>
        <div className="menu-x">
          <FaTimes onClick={toggleMenu} color="white" size="20" />
        </div>
        {renderMenu()}
      </div>
      {menuOpen && (
        <div
          className={`overlay ${overlayVisible ? 'visible' : ''}`}
          onClick={toggleMenu}
        />
      )}
    </>
  );
}

export default Menu;
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { TitleContext } from './../app/TitleContext';

function Conduct() {
    const { pageTitle, updatePageTitle } = useContext(TitleContext);
    useEffect(() => {
        updatePageTitle('Chatwatch - Code of Conduct')
    }, [])

    return (
        <div className="home-container">
            <div className="home-logo">
                <img src="/chatwatch-word.png" alt="" />
            </div>
            <div className="terms">
                <Link to="/channels"><button>Back</button></Link>
                <br></br>
                <h2>Chatwatch Code of Conduct</h2>
                <h3>Updated June 15, 2023</h3>
                <br></br>
                <h3><u>Age restriction:</u> You may not create an account or use this application if you are under the age of 13.</h3>
                <h3><u>Violent Speech:</u> You may not threaten, incite, glorify, or express desire for violence or harm.</h3>
                <h3><u>Violent & Hateful Entities:</u> You can't affiliate with or promote the activities of violent and hateful entities.
                </h3>
                <h3><u>Abuse/Harassment:</u> You may not engage in the targeted harassment of someone, or incite other people to do so.
                </h3>
                <h3><u>Hateful conduct:</u> You may not attack other people on the basis of race, ethnicity, national origin, caste, sexual orientation, gender, gender identity, religious affiliation, age, disability, or serious disease.
                </h3>
                <h3><u>Self-harm:</u> You may not promote or encourage suicide or self-harm.
                </h3>
                <h3><u>Sexual content:</u> You may not post messages that are excessively sexual in nature.
                </h3>
                <h3><u>Links:</u> You may not share links in the chat or attempt to direct users to another webpage
                </h3>
                <h3><u>Message filter:</u> You may not attempt to circumvent the message filter.
                </h3>
                <h3><u>Illegal activities:</u> You may not use our service for any unlawful purpose or in furtherance of illegal activities.
                </h3>
                <h3><u>Transactions:</u> You may not use our service to market, buy, sell, or facilitate transactions or any goods of services without express authorization and permission.
                </h3>
                <br></br>
                <h2>Privacy</h2>
                <br></br>
                <h3><u>Private Information:</u> You may not publish or post other people's private information (such as home phone number and address) without their express authorization and permission. We also prohibit threatening to expose private information or incentivizing others to do so.</h3>
                <h3><u>Account Compromise:</u> You may not use or attempt to use credentials, passwords, tokens, keys, cookies or other data to log into or otherwise access, add, delete or modify the private information or account features of any Chatwatch account other than your own (or those you have been directly authorized to do so via OAuth authorization or similar mechanism).</h3>
                <br></br>
                <h2>Authenticity</h2>
                <br></br>
                <h3><u>Platform Manipulation and Spam:</u> You may not use Chatwatch's services in a manner intended to artificially amplify or suppress information or engage in behavior that manipulates or disrupts people's experience on Chatwatch.</h3>
                <h3><u>Civic Integrity:</u> You may not use Chatwatch's services for the purpose of manipulating or interfering in elections or other civic processes. This includes posting or sharing content that may suppress participation or mislead people about when, where, or how to participate in a civic process.</h3>
                <h3><u>Misleading and Deceptive Identities:</u> You may not impersonate individuals, groups, or organizations to mislead, confuse, or deceive others, nor use a fake identity in a manner that disrupts the experience of others on Chatwatch.</h3>
                <h3><u>Synthetic and Manipulated Messages:</u> You may not employ bots, artificial intelligence, or any other mechanism to automatically generate or send messages.</h3>
                <br></br>
                <h2>Third-party advertising
                </h2>
                <br></br>
                <h3>You may not post any content on or through our services that includes third-party advertising or directs users to engage with or participate in any third-party event, service, marketing campaign, or contest without prior consent</h3>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    );
}

export default Conduct;

import React, {useContext, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { register, reset } from './features/auth/authSlice'
import { TitleContext } from './app/TitleContext';

function AuthSignup({email}) {
  // const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pageTitle, updatePageTitle } = useContext(TitleContext);
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const type = "google";
  const [error, setError] = useState("");

  const {processing, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )
  useEffect(() => {
    updatePageTitle('Chatwatch - Sign up')
}, [])

  useEffect(() => {
    if(isError){
      if(message === 'Username or email address already in use') {
        setError('Username already in use')
      } else if (message === email) {
        setError('')
      } else {
      setError(message)
      }
    }
    if(isSuccess){
      navigate('/account-created?auth=google');
    }
    dispatch(reset())
  }, [isError, isSuccess, message, navigate, dispatch])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(password != password2) {
      setError("Passwords do not match")
    } else {
      const userData = { username, email, password, type }
      if(!processing){
        dispatch(register(userData))
      }
    }
  }

  return (
    <>
        {/* <div className="home-container"> */}
          {/* <div className="home-logo">
            <img src="/chatwatch-word.png" alt="" />
          </div> */}
          <div className="login-container">
            <form>
            <h2>Create username and password</h2>
            <h3>Email: {email}</h3>
              <label>
                <input type="text" id="username" name="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
              </label>
              <label>
                <input type="password" id="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </label>
              <label>
                <input type="password" id="password2" name="password2" placeholder="Confirm password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
              </label>
                <div className="auth-error-message">
                  <h3>{error}</h3>
                </div>
              <button type="submit" onClick={handleSubmit}>Create account</button>
            </form>
            <div className='menu-bottom-links'>
              <Link to="/tos"><h3>Terms of Service</h3></Link>
              <Link to="/conduct"><h3>Code of Conduct</h3></Link>
              <Link to="/privacy"><h3>Privacy</h3></Link>
            </div>
          </div>
        </>
    // </div>
  );
}

/*
function Homepage({ user }) {
  return (
    <div className="home-container">
      {user ? (
        <div>
      <nav>
        <ul>

            <>
              <li>{user.username}</li>
              <li><Link to="/channels" className="btn">Chat now</Link></li>
              <li><Link to="/logout" className="btn">Log out</Link></li>
            </>
          ) : (
            <>
              <li><Link to="/login" className="login-btn">Log in</Link></li>
              <li><Link to="/signup" className="signup-btn">Sign up</Link></li>
            </>

        </ul>
      </nav>
      <div>
      <form>
        <label>
          Username:
          <input type="text" name="username" />
        </label>
        <br />
        <label>
          Password:
          <input type="password" name="password" />
        </label>
        <br />
        <button type="submit">Login</button>
      </form>
    </div>
      <div className="home-logo">
        <img src="/chatwatch.png" alt="" />
      </div>
      <div>
        <h2>Watch. Chat. Chatwatch.</h2>
        <h3>Join the audience with real-time chat for live TV</h3>
        <Link to="/channels" className="btn">Chat now</Link>
      </div>
         
      <footer>
        // include your footer here
      </footer>
    </div>
     )}
    </div>
  );
}
*/

export default AuthSignup;

import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { register, reset } from './features/auth/authSlice'
import axios from 'axios';
import './styles.css';
import { UserContext } from './components/darkModeContext';
import { TitleContext } from './app/TitleContext';

function Signup(props) {
  // const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [betaCode, setBetaCode] = useState("");
  const [error, setError] = useState("");
  const [displayErr, setDisplayErr] = useState(false);
  const { pageTitle, updatePageTitle } = useContext(TitleContext);

  const type = "form";

  // if (displayErr && (!username || !email || !password || !password2)) {
  //   setError("");
  //   setDisplayErr(false);
  // }

  const { processing, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    updatePageTitle('Chatwatch - Sign up')
}, [])

  useEffect(() => {
    if (isError) {
      console.log(message)
      setError(message)
      setDisplayErr(true)
    }
    if (isSuccess) {
      navigate('/account-created');
    }
    dispatch(reset())
  }, [isError, isSuccess, message, navigate, dispatch])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password != password2) {
      setError("Passwords do not match")
      setDisplayErr(true)
    } else {
      const userData = { username, email, password, type, betaCode }
      if (!processing) {
        dispatch(register(userData))
      }
    }
  }
  const toLogin = () => {
    navigate('/login')
  }
  return (
    <div>
      <div className="home-container">
        <div className="home-logo">
          <img src="/chatwatch-word.png" alt="" />
        </div>
        {/* <div className="beta-disclaimer">
              <h3>Chatwatch is currently in closed beta. Enter your beta code to register:</h3>
              </div> */}
        <div className="login-container">
          <form>
            {/*               
              <label>
                <input type="text" id="betacode" name="betacode" placeholder="Beta code" value={betaCode} onChange={(e) => setBetaCode(e.target.value)} />
              </label> */}
            <h2>Create your account</h2>
            <label>
              <input type="text" id="username" name="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </label>
            <label>
              <input type="text" id="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
              <input type="password" id="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            <label>
              <input type="password" id="password2" name="password2" placeholder="Confirm password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
            </label>
            {error && (<div className="auth-error-message">
              <h3>{error}</h3>
            </div>)}
            <button type="submit" onClick={handleSubmit}>Create account</button>
          </form>
          <div className='or-divider'>
            <hr></hr><span>or</span><hr></hr>
          </div>
          <label id="create-account">
            <button onClick={toLogin}>Log in</button>
          </label>
          <div className='menu-bottom-links'>
            <Link to="/tos"><h3>Terms of Service</h3></Link>
            <Link to="/conduct"><h3>Code of Conduct</h3></Link>
            <Link to="/privacy"><h3>Privacy</h3></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;

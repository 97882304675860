import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login, logout, updatePass, reset } from './features/auth/authSlice'
import Navbar from './Navbar'
import axios from 'axios'


function UpdatePassword() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const [verified, setVerified] = useState(false);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [error, setError] = useState("");
    const { user, processing, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )
    // const onLogout = () => {
    //     dispatch(logout())
    //     dispatch(reset())
    //     navigate('/')
    // }
    // useEffect(() => {
    //     if (user) {
    //         const userData = async (user) => {
    //             const response = await axios.post('/getemail', { token: user.token });
    //             if (response.data) {
    //                 if (response.data.email === "Token verification failed") {
    //                     onLogout();
    //                 }
    //             }
    //         };
    //         userData(user);
    //     }
    // }, [user]);


    useEffect(() => {
        if (isError) {
            setError(message)
        }
        if (isSuccess) {
            if (!verified) {
                dispatch(reset());
                setVerified(true);
            } else {
                navigate('/settings', { state: { message: "Password updated successfully." } });
            }
        }
        dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword != newPassword2) {
            setError("Passwords do not match")
        } else {
            if (!processing) {
                const username = user.username;
                const loginData = { username, password };
                dispatch(login(loginData))
            }
        }
    }
    useEffect(() => {
        if (verified) {
            if (!processing) {
                const token = user.token;
                const data = {token, user, newPassword }
                dispatch(updatePass(data))
            }
        }
    }, [dispatch, verified])

    return (
        <>
            <Navbar prevPage='/settings' />
            <div className="home-container">
                <div className="subheader">
                    <h2>Change Password</h2>
                </div>
                <br />
                <div className='settings-container'>
                    <form>
                        <label>
                            <input type="password" id="password" name="password" placeholder="Current Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </label><label>
                            <input type="password" id="newPassword" name="newPassword" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </label><label>
                            <input type="password" id="newPassword2" name="newPassword2" placeholder="Confirm New Password" value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)} />
                        </label>
                        <div className="auth-error-message">
                            <h3>{error}</h3>
                        </div>
                        <br />
                        <button type="submit" onClick={handleSubmit}>Update Password</button>
                        <div className='cancel'>
                            <button><Link to='/settings'>Cancel</Link></button>
                        </div>
                    </form>

                </div >
            </div>

        </>
    );
};
export default UpdatePassword;

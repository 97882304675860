import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { FiLogIn } from 'react-icons/fi';
import { reset, verifyEmail } from './features/auth/authSlice';

function Verification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const parama = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailToken = searchParams.get('token');

  const [verified, setVerified] = useState(false);
  const [response, setResponse] = useState('');
  const [displayMessage, setDisplayMessage] = useState(false);

  const { processing, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      console.log(message)
      setResponse(message)
      setDisplayMessage(true)
    }
    if (isSuccess) {
      setResponse(message)
      setDisplayMessage(true)
    }
    dispatch(reset())
  }, [isError, isSuccess, message, navigate, dispatch])

  useEffect(() => {
    if (!processing) {
      dispatch(verifyEmail(emailToken))
    }
  }, [])


  return (
    <div className="home-container">
      <div className="home-logo">
        <img src="/chatwatch-word.png" alt="" />
      </div>
      <div className="subheader">
        { displayMessage && (<h3>{response}</h3>)}
        <br></br>
      </div>
      <Link to="/login"><div className='menu-logout'>
        <button><FiLogIn size='1.2rem' />    Log in</button>
      </div></Link>
    </div>
  );
}

export default Verification;

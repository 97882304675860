import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { TitleContext } from './../app/TitleContext';

function Privacy() {
    const { pageTitle, updatePageTitle } = useContext(TitleContext);
    useEffect(() => {
        updatePageTitle('Chatwatch - Privacy Policy')
    }, [])
    return (
        <div className="home-container">
            <div className="home-logo">
                <img src="/chatwatch-word.png" alt="" />
            </div>
            <div className="terms">
                <Link to="/channels"><button>Back</button></Link>
                <br></br>
                <h2>Privacy Policy</h2>
                <h3>Updated June 15, 2023</h3>
                <br></br>
                <h3>
                    This Privacy Policy ("Policy") explains how the Chatwatch company ("we" or "us") collects, uses, and protects the personal information of users ("you" or "users") when using our chat application ("Chatwatch" or "the App"). We are committed to maintaining the privacy and security of your information.
                </h3>
                <br></br>
                <h2>
                    1. Information We Collect
                </h2>
                <br></br>
                <h3>
                    <u>1.1 Account Information:</u> When you create an account on the App, we collect your chosen username, email address, and password.
                </h3><h3>
                <u>1.2 Chat Data:</u> We collect user activity data including site navigation, messages sent, poll interactions, status, preferences, channel searches, filters, and favorites.
                </h3><h3>
                <u>1.3 Log Data:</u> Our servers automatically collect certain information when you use the App, including your IP address, device information, operating system, and timestamps of your interactions.
                </h3>
                <br></br>
                <h2>
                    2. Use of Information
                </h2>
                <br></br>
                <h3>
                <u>2.1 Providing and Improving the App:</u> We use user data and chat data to provide you with the functionalities of the App, including facilitating live chat interactions and ensuring the smooth operation of the service.
                </h3><h3>
                <u>2.2 Communication:</u> We may use your email address to send you important updates, notifications, and information related to the App. We do not share you email address or other user-specific information with third-parties.
                </h3><h3>
                <u>2.3 Analytics and Improvements:</u> We may use aggregated and anonymized data for analytical purposes to improve the App's performance, features, and user experience.
                </h3>
                <br></br>
                <h2>
                    3. Data Sharing and Disclosure
                </h2>
                <br></br>
                <h3>
                <u>3.1 Moderators:</u> We may share your chat data with our human moderators for the purpose of enforcing our <u><Link to="/conduct">Code of Conduct</Link></u> and ensuring compliance with the App's guidelines.
                </h3><h3>
                <u>3.2 Legal Compliance:</u> We may disclose your information if required by law, regulation, legal process, or governmental request.
                </h3><h3>
                <u>3.3 Business Transfers:</u> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.
                </h3>
                <br></br>
                <h2>
                    4. Data Security
                </h2>
                <br></br>
                <h3>
                <u>4.1 Data security:</u> We implement industry-standard security measures to protect your personal information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.
                </h3>
                <br></br>
                <h2>
                    5. GDPR
                </h2>
                <br></br>
                <h3>
                <u>5.1 App use in GDPR countries:</u> Chatwatch is intended for users based in the United States and Canada. Residents of countries in which GDPR is applicable may not create accounts or use this app at this time.
                </h3>
                <br></br>
                <h2>
                    6. Children's Privacy
                </h2>
                <br></br>
                <h3>
                <u>6.1 Children's Privacy:</u> The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child under 13, we will take steps to delete such information.                </h3>
                <br></br>
                <h2>
                    7. Changes to this Policy
                </h2>
                <br></br>
                <h3>
                <u>7.1 Policy Changes:</u> We may modify or update this Policy from time to time, and users will be notified of updates. We encourage users to review this Policy periodically.
                </h3>
                <br></br>
                <h2>
                    8. Contact Us
                </h2>
                <br></br>
                <h3>
                <u>8.1 Contact:</u> If you have any questions, concerns, or requests regarding this Policy or the privacy practices related to the App, please contact us at feedback@chatwatch.tv.
                    </h3>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    );
}

export default Privacy;

import axios from 'axios'
let API_URL = ''
if (process.env.NODE_ENV === 'production') {
    API_URL = process.env.REACT_APP_BASE_URL;
}

const getChannelData = async () => {
    const response = await axios.post(API_URL + '/channeldata')
    return response.data
}
const getUserCounts = async (channels) => {
    const response = await axios.post(API_URL + '/usercounts', { channels })
    return response.data
}
const postFeedback = async (user, feedback) => {
    const response = await axios.post(API_URL + '/feedback', { user, feedback })
    return response.data
}

const dataService = { getChannelData, getUserCounts, postFeedback }
export default dataService
const getGoogleOAuthURL = () => {
    const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
    const redirectUri = process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const options = {
        redirect_uri: redirectUri,
        client_id: googleClientId,
        access_type: 'offline',
        response_type: 'code',
        prompt: 'consent',
        scope: [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email'
        ].join(" ")
    }
    const qs = new URLSearchParams(options)

    return `${rootUrl}?${qs.toString()}`
}

module.exports = getGoogleOAuthURL
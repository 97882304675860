import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import dataService from './features/data/dataService';
import metricsService from './features/data/metricsService';
import 'react-toastify/dist/ReactToastify.css'
import { darkModeContext, DarkModeProvider } from './components/darkModeContext';
import Navbar from './Navbar';
import Login from './Login';
import Signup from './Signup';
import Logout from './Logout';
import ProgramList from './ProgramList';
import Chat from './Chat';
import Settings from './Settings';
import UpdateEmail from './UpdateEmail';
import UpdatePassword from './UpdatePassword'
import Feedback from './Feedback'
import BrowserDetection from './components/BrowserDetection'
import channelData from './Channels'
import Terms from './tos/Terms'
import Conduct from './tos/Conduct'
import Privacy from './tos/Privacy'
import Verification from './Verification'
import AccountCreated from './AccountCreated'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import GoogleOAuth from './GoogleOAuth'
import { TitleContext } from './app/TitleContext';

function App() {
  let channels = [];
  for (const channel of channelData) {
    channels.push(channel.link);
  }

  const [loading, setLoading] = useState(true);
  const [livePrograms, setLivePrograms] = useState([]);
  const [sportsPrograms, setSportsPrograms] = useState([]);
  const [userCounts, setUserCounts] = useState({});
  const [online, setOnline] = useState(false);
  const [userId, setUserId] = useState('');
  const [platform, setPlatform] = useState('');
  const [browser, setBrowser] = useState('');
  const [browserInfo, setBrowserInfo] = useState({});
  const [webShare, setWebShare] = useState(null);
  const titleContext = useContext(TitleContext);
  const { pageTitle, updatePageTitle } = titleContext;

  if (platform && browser && Object.keys(browserInfo).length === 0) {
    const info = {
      platform: platform,
      browser: browser
    }
    setBrowserInfo(info)
  }

  if (!userId) {
    const id = "#user" + Math.floor(Math.random() * 100000000).toString().padStart(12, '0')
    setUserId(id)
  }
  const fetchLivePrograms = async () => {
    try {
      const data = await dataService.getChannelData();
      setLivePrograms(data.live)
      setSportsPrograms(data.sports)
      setTimeout(() => {
        setLoading(false); // Data fetching is complete after a 1-second delay
      }, 500);
    } catch (error) {
      console.error('Error fetching live program data:', error);
      setLoading(false); // Data fetching is complete
    }
  };

  const fetchUserCounts = async (channels) => {
    try {
      const counts = await dataService.getUserCounts(channels);
      setUserCounts(counts);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchLivePrograms();
    fetchUserCounts(channels);
    const interval = setInterval(() => {
      fetchLivePrograms();
      fetchUserCounts(channels);
    }, 1 * 60 * 1000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, []);
  // Client check in with server
  useEffect(() => {
    const checkInInterval = setInterval(() => {
      if (!document.hidden) {
        metricsService.checkIn(userId);
      }
    }, 1 * 60 * 1000); // Check-in interval: 1 minute
    const timeout = setTimeout(() => { // Wait 3 seconds for first check-in (to stop double check in)
      if (!browserInfo) {
        browserInfo = {}
      }
      if (!online && !document.hidden) {
        metricsService.checkIn(userId, browserInfo);
      }
      setOnline(true)
    }, 3000);
    return () => {
      clearInterval(checkInInterval);
      clearTimeout(timeout);
    };
  }, [browserInfo]);


  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <>
          <BrowserDetection setPlatform={setPlatform} setBrowser={setBrowser} />
          <Routes>
            <Route path="/" element={<ProgramList livePrograms={livePrograms} userCounts={userCounts} loading={loading} page={"channels"} />} />
            <Route path="/channels" element={<ProgramList livePrograms={livePrograms} userCounts={userCounts} loading={loading} page={"channels"} />} />
            <Route path="/sports" element={<ProgramList livePrograms={sportsPrograms} userCounts={userCounts} loading={loading} page={"sports"} />} />
            <Route path="/login" element={<Login userId={userId} />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/chat/:channelLink" element={<Chat
              livePrograms={livePrograms}
              sportsPrograms={sportsPrograms}
              userId={userId}
              online={online}
              setOnline={setOnline}
              browserInfo={browserInfo}
              webShare={webShare}
            />} />
            <Route path="/chat" element={<Navigate to="/channels" replace />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/update-email" element={<UpdateEmail />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/tos" element={<Terms />} />
            <Route path="/conduct" element={<Conduct />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/verification/" element={<Verification />} />
            <Route path="/account-created" element={<AccountCreated />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/oauth/google" element={<GoogleOAuth userId={userId} />} />
          </Routes>
        </>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;

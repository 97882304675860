import React, { useRef, useEffect, useState } from 'react';

import {
    TwitterShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    TwitterIcon,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon
} from 'react-share';

const ShareButtons = ({ shareOpen, setShareOpen, showName, shareUrl }) => {

    const overlayRef = useRef(null);
    const [overlayVisible, setOverlayVisible] = useState(false);

    const closeShare = () => {
        setShareOpen(false);
    }

    useEffect(() => {
        if(shareOpen) {
          setOverlayVisible(true)
        }
        if(!shareOpen) {
          setOverlayVisible(false)
        }
      }, [shareOpen])

    useEffect(() => {
        const handleClickOutside = (event) => {
            const chatButton = document.getElementById('submitMessage');
            const isEnterKey = event.key === 'Enter';
            const isClickOutside = !overlayRef.current.contains(event.target);
            if (shareOpen && isEnterKey) {
                closeShare();
                chatButton.click();
            }
            if (shareOpen && isClickOutside) {
                console.log('outside')
                closeShare();
            }
        };

        if (shareOpen) {
            document.addEventListener('click', handleClickOutside);
            document.addEventListener('keydown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('keydown', handleClickOutside);
        };
    }, [shareOpen, closeShare]);

    const title = `Join me in the live chat for ${showName}!`;
    const body = `Come join the live chat for ${showName}: https://www.chatwatch.tv/chat/${shareUrl}`;

    return (
        <>
            {shareOpen && (
                <>
                    <div className={`share-modal ${overlayVisible ? 'visible' : ''}`} ref={overlayRef}>
                        <div className="share-content">
                            <div className='share-header'>
                                <h3>Share this live chat with your friends!</h3>
                            </div>
                            <TwitterShareButton url={`https://www.chatwatch.tv/chat/${shareUrl}`} title={title}>
                                <TwitterIcon size={32} round={false} />

                                Share on Twitter
                            </TwitterShareButton>
                            <FacebookShareButton url={`https://www.chatwatch.tv/chat/${shareUrl}`} quote={title}>
                                <FacebookIcon size={32} round={false} />

                                Share on Facebook
                            </FacebookShareButton>
                            <WhatsappShareButton url={`https://www.chatwatch.tv/chat/${shareUrl}`} title={title}>
                                <WhatsappIcon size={32} round={false} />

                                Share on WhatsApp
                            </WhatsappShareButton>
                            <EmailShareButton url={`https://www.chatwatch.tv/chat/${shareUrl}`} subject={title} body={body}>
                                <EmailIcon size={32} round={false} />
                                Share via Email
                            </EmailShareButton>

                        </div>
                    </div>
                    <div className={`overlay ${overlayVisible ? 'visible' : ''}`}
                        onClick={closeShare}>
                    </div>
                    </>
                    )}
                </>
    );
};

export default ShareButtons;

import React from 'react'

const ChannelsFilter = ({searchValue, setSearchValue, filterValue, setFilterValue, clearFilters}) => {


    return (
        <div className="channels-header">
            <div className='channels-filter-container'>
                <div className='channels-filter-options'>
                    <div className='channels-filter-search'>
                        <input
                            type="text"
                            id="channel-search"
                            name="channel-search"
                            placeholder="Search..."
                            value={searchValue}
                            inputMode='search'
                            autoComplete='off'
                            maxLength='25'
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                    <div className='channels-filter-dropdown'>
                        <select value={filterValue} onChange={(e) => setFilterValue(e.target.value)}>
                            <option value="">All</option>
                            <option value="popular">Popular</option>
                            <option value="news">News</option>
                            <option value="sports">Sports</option>
                        </select>
                    </div>
                </div>
                <div className='channels-filter-clear'>
                    <button onClick={clearFilters} >Clear</button>
                </div>
            </div>
        </div>
    )
}

export default ChannelsFilter
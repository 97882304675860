import React, { useEffect } from 'react';

const BrowserDetection = ({setPlatform, setBrowser}) => {
  useEffect(() => {
    const platform = getPlatform();
    setPlatform(platform);

    if (navigator.userAgentData) {
      navigator.userAgentData.getHighEntropyValues(['brands'])
        .then((uaData) => {
          const brands = uaData.brands;
          if (brands && brands.length > 0) {
            const browserName = brands[0].brand;
            setBrowser(browserName)
          } else {
            setBrowser('unknown')
          }
        })
        .catch((error) => {
          console.error('Error retrieving User Agent data:', error);
        });
    }
  }, []);

  const getPlatform = () => {
    const userAgent = navigator.userAgent;
    
    if (/(Win64|Win32|WOW64)/i.test(userAgent)) {
      return 'Windows';
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
      return 'Mac';
    } else if (/Linux/i.test(userAgent)) {
      return 'Linux';
    } else if (/Android/i.test(userAgent)) {
      return 'Android';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return 'iOS';
    } else {
      return 'Unknown';
    }
  };

  return null;
};

export default BrowserDetection
import React, {useState, useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import getGoogleOAuthURL from './features/auth/getGoogleUrl';
import { login, resendVerification, reset } from './features/auth/authSlice'
import { TitleContext } from './app/TitleContext';
import './styles.css';
import { FiLogIn } from 'react-icons/fi';
import { FaGoogle } from 'react-icons/fa';

function Login(props) {
  // const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [heldUsername, setHeldUsername] = useState("");
  const { pageTitle, updatePageTitle } = useContext(TitleContext);


  // if (displayErr && (!username || !password)) {
  //   setError("");
  //   setDisplayErr(false);
  // }

  const {user, processing, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if(isError){
      setError(message)
      if (message === "Email address has not yet been verified.") {
      setShowResend(true)
      }
    }
    if(user && user.username){
      navigate('/channels');
    }
    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  useEffect(() => {
    updatePageTitle('Chatwatch - Log in')
}, [])

  const [selectData, setSelectData] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!username || !password) {
      setError("Enter username and password")
    } else {
      const userData = { username, userId: props.userId, password }
      if(!processing){
        dispatch(login(userData))
        setHeldUsername(userData.username)
      }
    }
  }
  const handleResendVerification = () => {
    setShowResend(false);
    if(!processing){
    dispatch(resendVerification(heldUsername))
    dispatch(reset)
    navigate('/account-created');
    }
  }
  const handleGoogle = () => {
    const link = getGoogleOAuthURL();
    window.location.href = link;
  }
  const toSignup = () => {
    navigate('/signup')
  }
  return (
    <div>
        <div className="home-container">
          <div className="home-logo">
            <img src="/chatwatch-word.png" alt="" />
          </div>
          <div className="subheader">
            <h2>Watch. Chat. Chatwatch.</h2>
            <h3>Join the audience with</h3>
            <h3>real-time chat for live TV</h3>
          </div>
          <div className="login-container">
          <label id="google">
                <button onClick={handleGoogle}>< FaGoogle />Log in with Google</button>
              </label>
            <form id="login">
                     <div className='or-divider'>
            <hr></hr><span>or</span><hr></hr>
              </div>
              <label>
                <input type="text" id="username" name="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
              </label>
              <label>
                <input type="password" id="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </label>
                {error && (<div className="auth-error-message">
                  <h3>{error}</h3> { showResend && ( 
                 <h4 id="resend" onClick={handleResendVerification}><u>Resend verification email</u></h4>
                )}
                </div>)}
                <button type="submit" onClick={handleSubmit}><FiLogIn size='1.2rem' />Log In</button>
              <Link to="/forgot-password" className="forgot-password">Forgot password?</Link>
            </form>
              <div className='or-divider'>
            <hr></hr><span>or</span><hr></hr>
              </div>
            <label id="create-account">
            <button onClick={toSignup}>Create Account</button>
              </label>
              <div className='menu-bottom-links'>
              <Link to="/tos"><h3>Terms of Service</h3></Link>
              <Link to="/conduct"><h3>Code of Conduct</h3></Link>
              <Link to="/privacy"><h3>Privacy</h3></Link>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Login;
